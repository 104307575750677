import React, { Component } from 'react';
import axios from 'axios';
import * as d3 from 'd3';
import { GeoJSON, Map, LayersControl, TileLayer } from 'react-leaflet';
import stateData from '../data/states_geo.json';
import countyData from '../data/counties_geo.json';
import { subscribeToBlocks } from '../util/SocketUtils';
import {
  navigate
} from "@reach/router";

const { BaseLayer } = LayersControl

const viewport = {
  center: [37.8, -96],
  zoom: 5
};

class ResultsMap extends Component {
  constructor() {
    super()
    let self = this;
    this.stateData = stateData;
    this.countyData = countyData;
    this.stateList = {};
    this.countyList = {};

    subscribeToBlocks(function (err, blocks) {

    });

    // set the size of increments to use before changing to the next color level
    this.colorRangeSize = 10;

    // store all color values
    this.colorLegend = [
      "rgb(202,227,255)",
      "rgb(126,186,255)", 
      "rgb(49,146,255)", 
      "rgb(39,117,204)", 
      "rgb(29,86,150)", 
      "rgb(1,61,127)", 
      "rgb(1,34,71)"
    ];

    this.stateData.features.forEach(value => {
      self.stateList[value.properties.STATE] = {
        name: value.properties.NAME,
        abbreviation: value.properties.ABBREVIATION
      }
    });

    this.state = {
      viewport: viewport,
      stateCounts: {},
      countyCounts: {}
    }

    this.handleStateFeature = function (feature, layer) {
      layer.bindPopup(self.getStatePopupContent);
      layer.on({
        click: function () {
          let stateAbbreviation = feature.properties.ABBREVIATION;
          let stateCount = self.state.stateCounts[stateAbbreviation];
          if (stateCount > 0) {
            navigate('state/' + stateAbbreviation);
          }
        },
        mouseover: function () {
          if (self.getStateCount(feature) > 0 && !layer.isPopupOpen()) {
            console.log("Moused over: " + feature.properties.ABBREVIATION);
            this.openPopup();
          }
        },
        mouseout: function () {
          if (self.getStateCount(feature) > 0 && layer.isPopupOpen()) {
            console.log("Moused out: " + feature.properties.ABBREVIATION);
            this.closePopup();
          }
        }
      });
    }

    this.getCountyCount = function (feature) {
      let countyIdentifier = self.findStateAbbreviation(feature.properties.STATE) + "_" + feature.properties.NAME;
      return self.state.countyCounts[countyIdentifier];
    }

    this.getStateCount = function (feature) {
      return self.state.stateCounts[feature.properties.ABBREVIATION];
    }

    this.getStatePopupContent = function (layer) {
      return "State of " + self.findStateAbbreviation(layer.feature.properties.STATE) + " has " + self.getStateCount(layer.feature) + " incidents.";
    }
    this.getCountyPopupContent = function (layer) {
      return "County of " + layer.feature.properties.NAME + "/" + self.findStateAbbreviation(layer.feature.properties.STATE) + " has " + self.getCountyCount(layer.feature) + " incidents.";
    }

    this.handleCountyFeature = function (feature, layer) {
      layer.bindPopup(self.getCountyPopupContent);
      layer.on({
        click: function () {
          // click on county does what?
        },
        mouseover: function () {
          if (self.getCountyCount(feature) > 0 && !layer.isPopupOpen()) {
            let countyIdentifier = self.findStateAbbreviation(feature.properties.STATE) + "_" + feature.properties.NAME;
            console.log("Moused over: " + countyIdentifier);
            this.openPopup();
          }
        },
        mouseout: function () {
          if (self.getCountyCount(feature) > 0 && !layer.isPopupOpen()) {
            let countyIdentifier = self.findStateAbbreviation(feature.properties.STATE) + "_" + feature.properties.NAME;
            console.log("Moused out: " + countyIdentifier);
            this.closePopup();
          }
        }
      });
    }

    this.getColor = function(numberOfCases) {
      if (numberOfCases && numberOfCases > 0) {
        let range = Math.floor(numberOfCases / self.colorRangeSize)
        range = Math.min(range, self.colorLegend.length);

        return self.colorLegend[range];
      }

      return null;
    }

    this.styleByCount = function (feature, count) {
      let fillColor = self.getColor(count);
      let style = {
        fillColor: fillColor,
        weight: 1,
        opacity: 1,
        color: 'black',
        fillOpacity: 1
      };
      
      if (fillColor != null) {
        style.fillOpacity = 1;
      } else {
        // make the color almost transparent
        style.fillOpacity = 0.10;
      }

      return style;
    }

    this.findStateAbbreviation = function (stateId) {
      return this.stateList[stateId] !== undefined ? this.stateList[stateId].abbreviation : null;
    }
    this.styleState = function (feature) {
      return self.styleByCount(feature, self.getStateCount(feature));
    }

    this.styleCounty = function (feature) {
      return self.styleByCount(feature, self.getCountyCount(feature));
    }
  }

  componentDidMount() {
    // get the svg that just mounted - this is componentDidMount()
    // so this function gets fired just after render()
    let svgLegend = d3.select('#legend');
    var self = this;
    // inject standard D3 code
    svgLegend.append("g")
      .selectAll("rect")
      .data(self.colorLegend)
      .enter()
      .append("rect")
      .attr("fill", function (d, i) { return self.colorLegend[i]; })
      .attr("x", function (d, i) { return (i * 30); })
      .attr("y", 0)
      .attr("width", 30)
      .attr("height", 20);

    // add numbers as labels
    let labelsLegend = [];
    let range = 0;
    do {
      if (range === 0) {
        labelsLegend.push("1");
      } else {
        labelsLegend.push(range + (labelsLegend.length === self.colorLegend.length - 1 ? "+" : ""));
      }
      range += self.colorRangeSize;
    } while (labelsLegend.length < self.colorLegend.length);

    svgLegend.append("g")
      .selectAll("text")
      .data(labelsLegend)
      .enter()
      .append("text")
      .attr("class", "legend-text")
      .attr("x", function (d, i) { return (i * 30) + 8; })
      .attr("y", 35)
      .text(function (d) { return d; })

    // using axios directly to avoid redirect interceptor
    axios({
      method: 'get',
      url: '/api/queryAllLabs',
      headers: {
        'Authorization': 'Bearer ' + window.sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    }).then(function (res) {
      var data = [];
      var stateCounts = {};
      var countyCounts = {};

      res.data.forEach(element => {
          data.push(element.Record);
          stateCounts[element.Record.state] = stateCounts[element.Record.state] === undefined ? 1 : ++stateCounts[element.Record.state];
          
          let countyIdentifier = element.Record.state + "_" + element.Record.county;
          countyCounts[countyIdentifier] = countyCounts[countyIdentifier] === undefined ? 1 : ++countyCounts[countyIdentifier];
          // Assuming county is full FIPS identifier of state and county. Otherwise need to concat state + county
          // countyCounts[element.Record.county] = countyCounts[element.Record.county] === undefined ? 1 : ++countyCounts[element.Record.county];
      });

      self.setState({stateCounts: stateCounts, countyCounts: countyCounts});

    }).catch(function (err) {
      console.log(err);
    });
  }

  render() {
    // const position = [this.state.lat, this.state.lng];
    return (
      <div className="App">
        <div>
        Influenza Counts (by State/County):
        </div>

        <svg id='legend'></svg>

        <Map viewport={viewport} zoom={5}>
          <TileLayer
            attribution='Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          />
          <LayersControl position="topright">
            <BaseLayer checked name="States">
              <GeoJSON data={stateData} style={this.styleState} onEachFeature={this.handleStateFeature} />
            </BaseLayer>
            <BaseLayer name="Counties">
              <GeoJSON data={countyData} style={this.styleCounty} onEachFeature={this.handleCountyFeature} />
            </BaseLayer>
          </LayersControl>
        </Map>
      </div>
    );
  }
}


export default ResultsMap;